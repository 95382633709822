<template>
  <div class="course-table clearfix" :style="{ width: `${tableWidth}px` }">
    <div class="top-table clearfix" :style="{ height: `${dayHeight*1.5}px`, top: `${stickyTop}px` }">
      <div class="menu" :style="{ width: `${sectionWidth*2}px` }">时间</div>
      <div class="week-table  clearfix">
        <div v-for="(day, dayIndex) in weekTable" :class="[
              'day',
              'content-center',
              showTimeline && nowDay == dayIndex + 1 ? 'active' : '',
            ]" :key="dayIndex" :style="{ width: `${dayWidth}px` }">
          {{ day }} <br /> {{dateArray[dayIndex]}}
        </div>
      </div>
    </div>
    <div class="clearfix position-relative">
      <div class="section-table" :style="{ width: `${sectionWidth*2}px` }">
        <div>
          <div class="section content-center" v-for="(part, partIndex) in parts" :key="partIndex" :style="{
                height: `${part.height}px`,
              }">
          </div>
        </div>
        <div class="parts">
          <div v-for="(item, index) in markers" :key="index" :style="{ height: `${item.height}px` }" class="part">
            <div class="label">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="all-courses">
        <div class="clearfix coursesList">
          <div v-for="(day, dayIndex) in weekTable" :class="[
                'col',
                ''
              ]" :key="dayIndex" :style="{ width: `${dayWidth}px` }">
            <div class="cell" v-for="(part, partIndex) in parts" :key="partIndex" :style="{ height: `${part.height}px` }">
              <div class="cell-son" :style="{ height:`${ part.height/part }px` }">
              </div>
            </div>
          </div>
        </div>
        <div>
          <div v-for="(course) in courses" :key="course.Id" class="course-wrap" :style="{
                width: `${dayWidth}px`,
                height: `${
                  ((course.end[0] - course.start[0]) * 60 +
                    (course.end[1] - course.start[1])) *
                  pixelOfMinutes
                }px`,
                left: `${(course.day - 1) * dayWidth}px`,
                top: `${
                  ((course.start[0] - parts.morning.start[0]) * 60 +
                    course.start[1]) *
                  pixelOfMinutes
                }px`,
              }">
            <div class="course-content course-status1" v-if="course.Status===1">
              <div>
                <i class="el-icon-time"></i> {{ course.start[0] | formatTime }}:{{
                    course.start[1] | formatTime
                  }}
                - {{ course.end[0] | formatTime }}:{{
                    course.end[1] | formatTime
                  }}
              </div>
              <div><i class="el-icon-map-location"></i> {{ course.SiteName }}</div>
              <div class="actions clearfix">
                <div @click="checkCourse(course)" v-if="course.Status!==1" class="action check">详情</div>
                <div @click="checkDetail(course)" v-if="course.Status===1" class="action read">详情</div>
                <div @click="editCourse(course)" v-if="course.Status===1" class="action edit">编辑</div>
                <div @click="removeCourse(course)" v-if="course.Status===1" class="action delete">取消</div>
              </div>
            </div>
            <div class="course-content course-status2" v-if="course.Status===2">
              <div>
                <i class="el-icon-time"></i> {{ course.start[0] | formatTime }}:{{
                    course.start[1] | formatTime
                  }}
                - {{ course.end[0] | formatTime }}:{{
                    course.end[1] | formatTime
                  }}
              </div>
              <div><i class="el-icon-map-location"></i> {{ course.SiteName }}</div>
              <div class="actions clearfix">
                <div @click="checkCourse(course)" v-if="course.Status!==1" class="action check">详情</div>
                <div @click="checkDetail(course)" v-if="course.Status===1" class="action read">详情</div>
                <div @click="editCourse(course)" v-if="course.Status===1" class="action edit">编辑</div>
                <div @click="removeCourse(course)" v-if="course.Status===1" class="action delete">取消</div>
              </div>
            </div>
            <div class="course-content course-status3" v-if="course.Status===3">
              <div>
                <i class="el-icon-time"></i> {{ course.start[0] | formatTime }}:{{
                    course.start[1] | formatTime
                  }}
                - {{ course.end[0] | formatTime }}:{{
                    course.end[1] | formatTime
                  }}
              </div>
              <div><i class="el-icon-map-location"></i> {{ course.SiteName }}</div>
              <div class="actions clearfix">
                <div @click="checkCourse(course)" v-if="course.Status!==1" class="action check">详情</div>
                <div @click="checkDetail(course)" v-if="course.Status===1" class="action read">详情</div>
                <div @click="editCourse(course)" v-if="course.Status===1" class="action edit">编辑</div>
                <div @click="removeCourse(course)" v-if="course.Status===1" class="action delete">取消</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>

export default {
  name: "SchedulingTable",
  model: {
    prop: "courses",
    event: "change",
  },
  props: {
    courses: null,
    showTimeline: {
      type: Boolean,
      default: false,
    },
    stickyTop: {
      type: Number,
      default: 1,
    },
    dateArray: {
      type: Array,
      default: [1, 2, 3, 4, 5, 6, 7]
    }
  },
  data() {
    return {
      // 一周天数
      weekTable: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期天"],
      // 一天节数
      sectionTable: ["早", "上", "下", "晚"],
      // 节数栏宽度
      sectionWidth: 50,
      // 天数栏高度
      dayHeight: 40,
      // 一节宽度
      dayWidth: 160,
      // 一节高度
      sectionHeight: 50,
      // 一分钟所占像素
      pixelOfMinutes: 2,
      // now-timeline 顶部定位
      nowTimelineTop: 0,
      // now time str
      nowTimeStr: "00:00",
      // 周几
      nowDay: 0,
      // 定时器
      timer: null,

      week: [],
      same_week: [],//保存当前最新的时间
      same_day: '',//当天的时间
    };
  },
  created() {
    this.timer = setInterval(() => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      if (
        hours < this.parts.morning.start[0] ||
        hours >= this.parts.night.end[0]
      ) {
        this.nowTimelineTop = 0;
        return;
      }
      this.nowDay = now.getDay();
      if (this.day === 0) {
        this.nowDay = 7;
        return;
      }
      this.nowTimelineTop =
        ((hours - this.parts.morning.start[0]) * 60 + minutes) *
        this.pixelOfMinutes;
      this.nowTimeStr = `${this.formatTime(hours)}:${this.formatTime(minutes)}`;
    }, 1000);
  },


  filters: {
    formatTime(num) {
      num = "" + num;
      return num[1] ? num : "0" + num;
    },
  },
  computed: {
    // 刻度
    markers() {
      const startHours = this.parts.morning.start[0];
      const endHours = this.parts.night.end[0];
      const data = [];
      const heightOfHours = 60 * this.pixelOfMinutes;
      for (let i = startHours; i < endHours; i++) {
        data.push({
          label: `${this.formatTime(i)}:00`,
          height: heightOfHours,
        });
      }
      return data;
    },
    courseStyle() {
      let bg = '#' + Math.floor(Math.random() * (2 << 23)).toString(16);
      return "background-color: " + bg + ";";
    },
    // 表格宽度
    tableWidth() {
      const tableWidth =
        this.sectionWidth * 2 + this.weekTable.length * this.dayWidth;
      return tableWidth;
    },
    // 表格高度
    tableHeight() {
      const tableHeight =
        this.dayHeight +
        this.morningHeight +
        this.forenoonHeight +
        this.afternoonHeight +
        this.nightHeight;
      return tableHeight;
    },
    parts() {
      return {
        // 早课240分钟
        morning: {
          name: "早",
          height: this.pixelOfMinutes * 240,
          start: [8, 0],
          end: [12, 0],
        },
        // 上午180分钟
        forenoon: {
          name: "上",
          height: this.pixelOfMinutes * 180,
          start: [12, 0],
          end: [15, 0],
        },
        // 下午180分钟
        afternoon: {
          name: "下",
          height: this.pixelOfMinutes * 180,
          start: [15, 0],
          end: [18, 0],
        },
        // 晚课240分钟
        night: {
          name: "晚",
          height: this.pixelOfMinutes * 240,
          start: [18, 0],
          end: [22, 0],
        },
      };
    },
  },
  methods: {


    // 格式化日期
    formatTime(num) {
      num = "" + num;
      return num[1] ? num : "0" + num;
    },
    // 选中课程
    editCourse(course) {
      this.$emit("edit", course);
    },
    // 删除课程
    removeCourse(course) {
      this.$emit("remove", course);
    },
    // 详情
    checkCourse(course) {
      this.$emit("check", course);
    },

    checkDetail(course) {
      this.$emit("read", course);
    },

  },
};

</script>
 
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-table {
  margin: 0 auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.menu {
  height: 100%;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-table {
  background-color: #fff;
  position: sticky;
  z-index: 2;
  display: flex;
  color: #6a757e;
  font-size: 14px;
  font-weight: bold;
}
.week-table {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  display: flex;
  height: 100%;
}
.week-table .day {
  text-align: center;
  height: 100%;
  border-right: 1px solid #eee;
  transition: all 1000ms;
}
.week-table .day.active {
  background-color: #e9ebfb;
  color: #222;
}
.col {
  transition: all 1000ms;
}
.col.active {
  background-color: #f5f6fd;
}
.section-table {
  position: relative;
  border-right: 1px solid #eee;
  text-align: center;
  border-left: 1px solid #eee;
}
.cell {
  position: relative;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  color: #ddd;
}
.cell-son {
  border-bottom: 1px solid #eee;
}
.all-courses {
  position: relative;
}
.coursesList {
  display: flex;
}
.add-course {
  height: 100%;
  font-size: 2em;
  color: transparent;
  background-color: transparent;
  transition: all 170ms;
}
.add-course:hover {
  background-color: #f9f9f9;
  cursor: pointer;
  color: #607d8b;
}
.course-wrap {
  position: absolute;
  z-index: 1;
  padding: 0 4px;
  color: #fff;
  transition: all 500ms;
}
.course-content {
  position: relative;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  padding: 4px 6px;
  font-size: 12px;
  transition: all 170ms;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.course-status1 {
  background-color: #ea7500;
}
.course-status2 {
  background-color: #8fcc80;
}
.course-status3 {
  background-color: #6a757e;
}
.course-content .actions {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 0%, 0.7);
}
.course-content .action {
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-content .action.read {
  width: (100%/3);
}
.course-content .action.delete {
  width: (100%/3);
  // width: 100%/3;
}
.course-content .action.edit {
  width: (100%/3);
}
.course-content .action.check {
  width: 100%;
}
.course-content .action:hover {
  color: #fff;
}
.course-content .action.read:hover {
  background-color: #3f51b5;
}
.course-content .action.delete:hover {
  background: crimson;
}
.course-content .action.edit:hover {
  background-color: #8fcc80;
}
.course-content .action.check:hover {
  background-color: #3f51b5;
}
.course-content:hover {
  cursor: pointer;
}
.course-content:hover .actions {
  display: block;
}
.parts {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.parts .part {
  position: relative;
}
.parts .label {
  position: absolute;
  top: -1px;
  width: 100%;
  height: 100%;
  line-height: 1;
  border-top: 1px solid #eee;
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6a757e;
  font-weight: 400;
  font-size: 14px;
}
.position-relative {
  position: relative;
  display: flex;
  flex: 1;
  height: 0;
  overflow-y: auto;
}
.now-timeline {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  height: 2px;
  background-color: crimson;
  pointer-events: none;
  transition: all 1000ms;
}
.now-timeline .label {
  position: absolute;
  transform: translate(0, -50%);
  line-height: 1;
  background-color: crimson;
  color: #fff;
  padding: 4px 0;
  text-align: center;
  font-weight: bold;
  border-radius: 3px;
}
.now-point {
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  background-color: crimson;
  transform: translate(0, -40%);
  border-radius: 10px;
  transition: all 1000ms;
}
</style>