<template>
  <div class="page page-box page-padding">
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="班级名称:">
          <el-select class="select-box" filterable collapse-tags placeholder="请选择班级" v-model="listQuery.ClassId" @change="changeClass">
            <el-option v-for="item in classList" :key="item.Id" :label="item.Name" :value="item.Id">
              <div class="select-option-box">
                <span class="option-left">{{ item.Name }}</span>
                <span class="option-right">{{ item.SubOrgName }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上课日期：">
          <div class="margin-date">
            <el-button size="small" @click="lastWeek" class="week-button">
              上一周
            </el-button>
            <el-date-picker @change="changeOne" v-model="listQuery.timesWeek" :clearable="false" type="week" :format="getList.scheduleStartDate + ' 至 ' + getList.scheduleEndDate" placeholder="选择周" :picker-options="onPicker" class="width-date" />
            <el-button size="small" @click="nextWeek" class="week-button">
              下一周
            </el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration">查询</el-button>
          <el-button v-preventReClick @click="resetSearch" class="plain-button">重置</el-button>
          <el-button @click="addCourse()" icon="el-icon-plus">
            <!-- <div class="img-center"> <img src="@/assets/add.png" alt="添加" class="add-img"> <span>新建课程</span></div> -->
            新建课程
          </el-button>
        </el-form-item>
      </el-form>
    </header>
    <main class="page-main page-main-table">
      <!--表格渲染-->
      <div class="right-box">
        <div></div>
        <div class="pointStatus">
          <span class="span1"></span>未开始课程
          <span class="span2"></span>进行中课程
          <span class="span3"></span>已结束课程
        </div>
      </div>
      <div v-loading='loading'>
        <SchedulingTable @remove="remove" @edit="addCourse" v-model="datasource" :dateArray="dataArray" @check="checkCourse" @read="checkDetail"></SchedulingTable>
      </div>
    </main>
    <!-- 详情 -->
    <el-dialog title="课程详情" :visible.sync="readVisible" width="40%" :close-on-click-modal='false'>
      <div class="class-content" v-loading='loading'>
        <el-row>
          <el-col>
            <h4>班级和课程时间</h4>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <span>课程班级：</span><span>{{ dateDetail.ClassName}}</span>
          </el-col>
          <el-col :span="10">
            <span>班级教练：</span><span>{{Array.isArray(dateDetail.Teachers)&&dateDetail.Teachers.map(item=>`${item.Name}(${item.TeacherType===1?'主教练':'助教'})`).join('/')}}</span>
          </el-col>
        </el-row>
        <el-row class="status-box">
          <el-col :span="10">
            <span>课程日期：</span>
            <span>
              {{ dateDetail.StartTime && moment(dateDetail.StartTime*1000).format('YYYY-MM-DD ') || '' }}
            </span>
          </el-col>
          <el-col :span="10">
            <span>课程时间：</span>
            <span>
              {{ (dateDetail.StartTime && moment(dateDetail.StartTime*1000).format('HH:mm'))+'至'+(dateDetail.EndTime && moment(dateDetail.EndTime*1000).format('HH:mm')) || '' }}
            </span>
            <span>
            </span>
          </el-col>
          <el-col :span="4">
            <div class="courseStatus">
              <span>
                {{ dateDetail.Status | courseStatus }}
              </span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <span>课程主题：</span><span>{{dateDetail.Theme }}</span>
          </el-col>
          <el-col :span="10">
            <span>课程时长：</span>
            {{Duration}}分钟
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <h4>课程内容</h4>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <template v-for="(item,index) in TrainMods">
              <div :key="index" class="padding-div">
                <div>{{`${index+1+'、'} ${item.Name} (${item.Duration}分钟)`}}</div>
                <div class="indent" style="white-space: pre-wrap;">{{item.Remarks}}</div>
              </div>
            </template>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <h4>上课要求</h4>
          </el-col>
        </el-row>
        <el-row class="site-name">
          <el-col :span="4"><span>课程场地：</span></el-col>
          <el-col :span="6" class="site-name">
            <span>{{ dateDetail.SiteName }}</span>
          </el-col>
          <el-col :span="4">
            <span>课程服装：</span>
          </el-col>
          <el-col :span="10" class="clothing-box">
            <template v-for="(item,index) in dateDetail.Clothings">
              <div class="radio-box" :key="index">
                <span>{{`${item.Name}(${item.Color})`}}</span>
                <el-image class="image-box" :src="`${cosHttp}${item.ImageAddress}`" :preview-src-list="[`${cosHttp}${item.ImageAddress}`]" />
              </div>
            </template>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <!-- 取消原因 -->
    <el-dialog title="取消原因" :visible.sync="cancelVisible" width="40%" :close-on-click-modal='false'>
      <el-form :model="cancelForm" :rules="rules" ref="cancelForm" label-width="100px">
        <el-form-item label="课程取消:" prop="Reason">
          <el-input v-model="cancelForm.Reason" :autosize="{ minRows: 3, maxRows: 5 }" type="textarea" placeholder="请输入课程取消原因" show-word-limit maxlength="200" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelVisible = false">取 消</el-button>
        <el-button type="success" @click="cancelClubCourse()" class="discoloration">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { getAllClubClass, getClubCourseList, cancelClubCourse } from '@/service/educational'
import SchedulingTable from "./SchedulingTable.vue";
import { deepClone } from '@/utils/index';  // 引入深拷贝

const ListQuery = {
  ClassId: '',
  timesWeek: Date.now(), // 按照当前默认时间
};

export default {
  name: 'ClassSchedule',
  components: {
    SchedulingTable
  },
  data() {
    const validateReason = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入取消课程原因'));
      }
      callback();
    };

    return {
      start: "", //起始日期
      end: "", //截止日期
      onPicker: {
        firstDayOfWeek: 1,
      },
      listQuery: deepClone(ListQuery),
      getList: {
        scheduleStartDate: '', // 开始日期
        scheduleEndDate: '', // 结束日期
      },
      dataArray: [], // 班级课表表头日期
      datasource: [],
      moment,
      loading: false,
      value: '',
      classList: [], // 所有班级列表
      tableList: [], // 课程列表
      readVisible: false, //详情弹窗初始状态
      dateDetail: {},
      cancelVisible: false, // 取消原因弹窗初始状态
      cancelForm: {
        Reason: '', // 初始化
      },
      rules: {
        Reason: [{ required: true, validator: validateReason, trigger: 'blur' }],
      },
      cosHttp: process.env.VUE_APP_COSHTTP,
      TrainMods: [], // 训练模块
    }
  },
  created() {
  },
  activated() {
    if (this.listQuery && !this.listQuery.ClassId) {
      this.resetGetList();
      this.getAllClubClass();
    } else {
      this.getClubCourseList();
    }
  },
  computed: {
    Duration() {
      let Duration = 0;
      Array.isArray(this.TrainMods) && this.TrainMods.forEach(item => {
        Duration += Number(item.Duration);
      })
      return Duration;
    }
  },

  methods: {

    /**
     * 时间重置
     */
    resetGetList() {
      // 86400000为一天时间戳  // 604800000为一周时间戳
      // 因为组件的限制，因为组件是从上周日到本周六，所有需要减去一天的时间戳
      //  写法大概为一周7天，减去当前已过天数，将天数转换成时间戳，最后再转换成日期
      this.getList.scheduleEndDate = this.fun(
        Date.parse(new Date()) +
        ((7 - new Date(Date.parse(new Date())).getDay()) * 24 * 60 * 60 * 1000));
      // 
      this.getList.scheduleStartDate = this.fun(
        Date.parse(new Date()) +
        ((7 - new Date(Date.parse(new Date())).getDay()) * 24 * 60 * 60 * 1000 + 86400000 - 604800000));

      this.changeDateArray();
    },

    /**
     * 获取课程列表数据
     */
    async getClubCourseList() {
      this.loading = true;
      let res = await getClubCourseList({
        ...this.listQuery,
        StartTime: new Date(moment(this.getList.scheduleStartDate).format('YYYY-MM-DD 00:00:00')).getTime() / 1000,
        EndTime: new Date(moment(this.getList.scheduleEndDate).format('YYYY-MM-DD 23:59:59')).getTime() / 1000,
      });
      this.loading = false;
      this.tableList = res.DataList;
      let arr = this.tableList.filter(item => {
        return item.Status !== 5;
      })
      this.datasource = arr.map((item) => {
        let obj = { ...item };
        obj.day = new Date(item.StartTime * 1000).getDay() || 7;
        obj.start = [new Date(item.StartTime * 1000).getHours(), new Date(item.StartTime * 1000).getMinutes()];
        obj.end = [new Date(item.EndTime * 1000).getHours(), new Date(item.EndTime * 1000).getMinutes()];
        return obj;
      })
    },

    /**
     * 获取所有班级列表
     */
    async getAllClubClass() {
      this.classList = await getAllClubClass();
      this.listQuery.ClassId = this.classList[0].Id;
      this.getClubCourseList();
    },
    changeClass() {
      // this.getClubCourseList();
    },

    /**
     * 查询
     */
    handleSearch() {
      this.getClubCourseList();
    },

    /**
     * 重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.listQuery.ClassId = this.classList[0].Id;
      this.listQuery.timesWeek = Date.now();
      this.resetGetList();
      this.getClubCourseList();
    },

    /**
     * 新增编辑课程
     */
    addCourse(course) {
      this.$router.push({
        path: '/educational/createCurriculum',
        query: {
          Id: course && course.Id || 0
        }
      });
    },

    /**
     * 取消课程
     */
    remove(course) {
      this.cancelVisible = true;
      this.cancelForm.Reason = '';  // 清空
      this.cancelForm.Id = [course.Id];  // 存值
    },
    async cancelClubCourse() {
      this.$refs.cancelForm.validate(async (valid) => {
        if (!valid) return;
        try {
          let res = await this.$confirm('你是否确定取消该课程？取消后创建的课程信息及二维码将会失效。', '警告', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确 定',
            cancelButtonText: '取 消',
            type: 'warning',
            center: true,
          })
          if (res !== 'confirm') return;
          await cancelClubCourse({ Ids: this.cancelForm.Id, Reason: this.cancelForm.Reason })
          this.$message.success('取消课程成功');
          this.cancelVisible = false;
          this.getClubCourseList();
        } catch (err) { };
      })
    },

    /**
     * 查看详情
     */
    checkCourse(course) {
      this.$router.push({
        path: '/educational/readDetail',
        query: {
          id: course.Id
        }
      });
    },

    checkDetail(course) {
      this.readVisible = true;
      this.TrainMods = course.TrainMods;
      this.dateDetail = { ...course };
    },

    /**
     * 上一周-下一周，课表时间筛选
     */
    //  转换时间格式
    fun(unixtimestamp) {
      const dt = new Date(unixtimestamp)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    // 当用户直接点击选择日期而不是点击进行以下操作
    // 此时val会默认传入星期一的时间戳
    changeOne(val) {
      const timeStamp = val.getTime(); // 标准时间转为时间戳，毫秒级别
      this.getList.scheduleStartDate = this.fun(
        timeStamp - 24 * 60 * 60 * 1000
      ); // 开始时间
      this.getList.scheduleEndDate = this.fun(
        timeStamp + 24 * 60 * 60 * 1000 * 5
      ); // 结束时间
      this.changeDateArray();
      this.handleSearch();
    },
    // 上一周
    // 604800000为一周的时间戳
    // 24 * 60 * 60 * 1000 * 7 = 604800000
    lastWeek() {
      this.getList.scheduleStartDate = this.fun(
        Date.parse(new Date(this.getList.scheduleStartDate)) - 604800000
      )
      this.listQuery.timesWeek = new Date(this.getList.scheduleStartDate);
      this.getList.scheduleEndDate = this.fun(
        Date.parse(new Date(this.getList.scheduleEndDate)) - 604800000
      )
      this.changeDateArray();
      this.handleSearch();
    },
    // 下一周
    nextWeek() {
      this.getList.scheduleStartDate = this.fun(
        Date.parse(new Date(this.getList.scheduleStartDate)) + 604800000
      )
      this.listQuery.timesWeek = new Date(this.getList.scheduleStartDate);
      this.getList.scheduleEndDate = this.fun(
        Date.parse(new Date(this.getList.scheduleEndDate)) + 604800000
      )
      this.changeDateArray();
      this.handleSearch();
    },

    // 获取当前周一周日期数据
    changeDateArray() {
      let arr = [];
      for (let i = 0; i < 7; i++) {
        arr.push(moment(new Date(this.getList.scheduleStartDate).getTime() + i * 24 * 60 * 60 * 1000).format('MM.DD'));
      }
      this.dataArray = arr;
    }
  }
}
</script>

<style lang='scss' scoped>
.page-main {
  display: flex;
  flex-direction: column;
  overflow: auto;
  > div {
    flex: 1;
  }
}

.page-main-table {
  padding: 0 24px 24px !important;
}

.margin-date {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 400px;
}

.week-button {
  color: #6a757e;
  font-size: 14px;
}
.width-date {
  width: 210px;
}
/deep/.el-input--prefix .el-input__inner {
  padding-right: 10px;
}
.clothing-box {
  display: flex;
  align-items: center;
}
.radio-box {
  display: flex;
  align-items: center;
  span {
    width: 80%;
    // margin-right: 24px;
  }
}
.site-name {
  display: flex;
  align-items: center;
}

.courseStatus {
  width: 50px;
  height: 50px;
  border: 1px solid #6a757e;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
}
.el-dialog .el-row .el-col {
  padding: 0;
  padding-left: 8px;
}
.class-content {
  // padding-left: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  font-weight: 400;
  color: #6a757e;
  font-size: 14px;
  h4 {
    margin: 10px 0;
    font-weight: 500;
    color: #21293a;
  }
}
.status-box {
  height: 50px;
  line-height: 50px;
}
.image-box {
  width: 20%;
  height: 20%;
}
.right-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6a757e;
  line-height: 48px;
  .pointStatus {
    display: flex;
    align-items: center;

    span {
      // display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin: 0 8px;
    }
    .span1 {
      background: #ff8900;
    }
    .span2 {
      background: #00b446;
    }
    .span3 {
      background: #a6b4bf;
    }
  }
}
</style>